import { FC } from "react"
import styled from "styled-components"

import { TextBlock as T } from "@lib/types"
import { RichText } from "./RichText"

interface Props {
  textBlock: T
  maxWidth?: number | undefined
}

export const TextBlock: FC<Props> = ({ textBlock, maxWidth }) => {
  const richText = textBlock?.richText
  const textAlign = textBlock?.textAlign
  const textColor = textBlock?.textColor

  return (
    <StyledTextBlock
      $textAlign={textAlign?.toLowerCase()}
      $textColor={textColor?.value}
      $maxWidth={maxWidth}
    >
      <RichText richText={richText} />
    </StyledTextBlock>
  )
}

interface StyleProps {
  $textAlign: string
  $textColor: string
  $maxWidth: number | undefined
}

const StyledTextBlock = styled.div<StyleProps>`
  color: ${({ $textColor }) => $textColor};
  text-align: center;
  ${({ $maxWidth }) => ($maxWidth !== undefined ? `max-width: ${$maxWidth}` : "")};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    text-align: ${({ $textAlign }) => $textAlign};
  }
`
