import { FC, createContext, useEffect, useState, useReducer, useContext } from "react"
import type {
  Checkout,
  CheckoutLineItemUpdateInput,
  CheckoutAttributesUpdateV2Input,
  CheckoutDiscountCodeApplyV2Payload,
  CheckoutCreatePayload,
  CheckoutLineItemsAddPayload,
  CheckoutLineItemsRemovePayload,
  CheckoutLineItemsUpdatePayload,
  CheckoutAttributesUpdateV2Payload,
} from "shopify-storefront-api-typings"

import {
  getCheckout,
  createCheckout,
  checkoutLineItemsAdd,
  checkoutLineItemsRemove,
  checkoutLineItemsUpdate,
  checkoutAttributesUpdateV2,
  checkoutDiscountCodeApplyV2,
} from "@lib/shopify"
import { loadState, saveState, LocalStorageKeys } from "@lib/utils"

const initialState = {
  currencyCode: "",
  id: "",
  webUrl: "",
  lineItems: {},
} as any

type Action = {
  type: "SET_CHECKOUT"
  payload: Checkout
}

type CheckoutResponse =
  | CheckoutDiscountCodeApplyV2Payload
  | CheckoutLineItemsRemovePayload
  | CheckoutLineItemsUpdatePayload
  | CheckoutLineItemsAddPayload
  | CheckoutCreatePayload
  | CheckoutAttributesUpdateV2Payload

export const CheckoutContext = createContext<Checkout | any>(initialState)

const reducer = (state: Checkout, action: Action) => {
  switch (action.type) {
    case "SET_CHECKOUT": {
      return action.payload
    }

    default: {
      return state
    }
  }
}

const localCheckouts = {
  "en-US": LocalStorageKeys.CHECKOUT,
  "en-GB": LocalStorageKeys.CHECKOUT_UK,
}

const localMiddleLayers = {
  "en-US": LocalStorageKeys.MIDDLELAYER,
  "en-GB": LocalStorageKeys.MIDDLELAYER_UK,
}
interface LocalStorageInterface {
  [key: string]: string
}

interface Props {
  locale: string
}

export const CheckoutProvider: FC<Props> = ({ children, locale }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [middleLayer, setMiddleLayer] = useState([])

  const getCountryCode = (locale: string) => (locale === "en-GB" ? "GB" : "US")

  const setNewCheckout = async (): Promise<void> => {
    const checkout_params = {
      buyerIdentity: {
        countryCode: getCountryCode(locale),
      },
    }
    const { checkout } = await createCheckout(getCountryCode(locale), checkout_params)
    saveState((localCheckouts as LocalStorageInterface)[locale])(checkout)
    saveState((localMiddleLayers as LocalStorageInterface)[locale])([])
    dispatch({ type: "SET_CHECKOUT", payload: checkout })
  }

  const setNewCurrencyCheckout = async (
    countryCode: string,
    forcedLocale?: string
  ): Promise<void> => {
    console.log("CALLED")
    console.log(countryCode)
    const lineItems =
      state.lineItems.edges.length === 0
        ? []
        : state.lineItems.edges.map(edge => {
            const quantity = edge.node.quantity
            const variantId = edge.node.variant?.id || ""
            return { quantity, variantId }
          })
    const checkout_params = {
      buyerIdentity: {
        countryCode: countryCode,
      },
      lineItems: lineItems,
    }
    const { checkout } = await createCheckout(countryCode, checkout_params)
    console.log({ checkout })
    console.log({ locale })
    saveState((localCheckouts as LocalStorageInterface)[forcedLocale || locale])(checkout)
    saveState((localMiddleLayers as LocalStorageInterface)[forcedLocale || locale])([])
    dispatch({ type: "SET_CHECKOUT", payload: checkout })
  }

  const hydrateCheckout = async (): Promise<void> => {
    const localCheckoutState = loadState((localCheckouts as LocalStorageInterface)[locale])
    const localMiddleLayerState = loadState((localMiddleLayers as LocalStorageInterface)[locale])
    localMiddleLayerState ? setMiddleLayer(localMiddleLayerState) : setMiddleLayer([])
    if (localCheckoutState?.id) {
      // There is a checkout id so we first set this as the localstorage's checkout
      dispatch({ type: "SET_CHECKOUT", payload: localCheckoutState })
      const checkout = await getCheckout(localCheckoutState.id)
      if (checkout?.completedAt) {
        // We've checked that the checkout from shopify has a completedAt, meaning it's completed so we need to fetch a new checkout
        setNewCheckout()
      }
    } else {
      setNewCheckout()
    }
  }

  useEffect(() => {
    hydrateCheckout()
  }, [locale])

  const dispatchCheckout = async (
    callbackPromise: Promise<CheckoutResponse>
  ): Promise<string[]> => {
    setLoading(true)
    const { checkout, checkoutUserErrors } = await callbackPromise
    setLoading(false)

    // if checkout id is invalid for some reason
    if (checkoutUserErrors?.length && checkoutUserErrors[0].message === "Checkout does not exist") {
      await setNewCheckout()
      return checkoutUserErrors.map(error => error.message)
    }

    if (checkoutUserErrors?.length) {
      setError(checkoutUserErrors[0].message)
      return checkoutUserErrors.map(error => error.message)
    }

    saveState((localCheckouts as LocalStorageInterface)[locale])(checkout as any)
    dispatch({ type: "SET_CHECKOUT", payload: checkout as any })
    return []
  }

  const addLineItem = async (newLineItem: { quantity: number; variantId: string }) => {
    await dispatchCheckout(
      checkoutLineItemsAdd({
        checkoutId: state.id,
        lineItems: [newLineItem],
      })
    )
    const handleMiddleLayerUpdate = (currentMiddleLayer: any) => {
      const newVariantIds = [...currentMiddleLayer] as any
      const targetIndex = newVariantIds.findIndex(
        (variantId: any) => variantId === newLineItem.variantId
      )
      // if item already exists, make sure it's pushed to the top again
      targetIndex !== -1 && newVariantIds.splice(targetIndex, 1)
      newVariantIds.unshift(newLineItem.variantId)
      saveState((localMiddleLayers as LocalStorageInterface)[locale])(newVariantIds)
      return newVariantIds
    }
    setMiddleLayer(middleLayer => handleMiddleLayerUpdate(middleLayer))
  }

  const addLineItems = async (lineItems: [{ quantity: number; variantId: string }]) => {
    await dispatchCheckout(
      checkoutLineItemsAdd({
        checkoutId: state.id,
        lineItems: lineItems,
      })
    )
    const handleMiddleLayerUpdate = (currentMiddleLayer: any) => {
      const newVariantIds = [...currentMiddleLayer] as any
      lineItems.forEach(newLineItem => {
        const targetIndex = newVariantIds.findIndex(
          (variantId: any) => variantId === newLineItem.variantId
        )
        // if item already exists, make sure it's pushed to the top again
        targetIndex !== -1 && newVariantIds.splice(targetIndex, 1)
        newVariantIds.unshift(newLineItem.variantId)
      })
      saveState((localMiddleLayers as LocalStorageInterface)[locale])(newVariantIds)
      return newVariantIds
    }
    setMiddleLayer(middleLayer => handleMiddleLayerUpdate(middleLayer))
  }

  const removeLineItem = async (lineItem: any) => {
    await dispatchCheckout(
      checkoutLineItemsRemove({
        checkoutId: state.id,
        lineItemIds: [lineItem.id],
      })
    )
    const handleMiddleLayerUpdate = (currentMiddleLayer: any) => {
      const newLineItems = [...currentMiddleLayer] as any
      const variantIdToRemove = lineItem.variant.id
      const indexToRemove = newLineItems.findIndex(
        (variantId: any) => variantId === variantIdToRemove
      )
      newLineItems.splice(indexToRemove, 1)
      saveState((localMiddleLayers as LocalStorageInterface)[locale])(newLineItems)
      return newLineItems
    }
    setMiddleLayer(middleLayer => handleMiddleLayerUpdate(middleLayer))
  }

  const removeLineItems = async (lineItems: any[]) => {
    const lineItemIds = lineItems.map(item => item.node.id)
    await dispatchCheckout(
      checkoutLineItemsRemove({
        checkoutId: state.id,
        lineItemIds,
      })
    )
    const handleMiddleLayerUpdate = (currentMiddleLayer: any) => {
      const newLineItems = [...currentMiddleLayer] as any
      lineItems.forEach(lineItem => {
        const variantIdToRemove = lineItem.node.variant.id
        const indexToRemove = newLineItems.findIndex(
          (variantId: any) => variantId === variantIdToRemove
        )
        newLineItems.splice(indexToRemove, 1)
      })
      saveState((localMiddleLayers as LocalStorageInterface)[locale])(newLineItems)
      return newLineItems
    }
    setMiddleLayer(middleLayer => handleMiddleLayerUpdate(middleLayer))
  }

  const updateLineItem = async (lineItem: CheckoutLineItemUpdateInput) =>
    await dispatchCheckout(
      checkoutLineItemsUpdate({
        checkoutId: state.id,
        lineItems: [lineItem],
      })
    )

  const updateCheckoutAttributes = async (input: CheckoutAttributesUpdateV2Input) =>
    await dispatchCheckout(
      checkoutAttributesUpdateV2({
        checkoutId: state.id,
        input,
      })
    )

  const applyDiscountCode = async (
    discountCode: CheckoutDiscountCodeApplyV2Payload
  ): Promise<string[]> => {
    return await dispatchCheckout(
      checkoutDiscountCodeApplyV2({
        checkoutId: state.id,
        discountCode,
      })
    )
  }

  const value = {
    ...state,
    middleLayer,
    error,
    loading,
    addLineItem,
    addLineItems,
    removeLineItem,
    updateLineItem,
    removeLineItems,
    updateCheckoutAttributes,
    applyDiscountCode,
    setNewCurrencyCheckout,
  }

  return <CheckoutContext.Provider value={value}>{children}</CheckoutContext.Provider>
}
