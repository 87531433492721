import { FC, useEffect, useState } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"
import { AnnouncementBar, Navbar, Subscribe, SearchModal } from "@components/common"
import Script from "next/script"
import { useCustomer, useGeoIp, useSite, useCheckout } from "@hooks/index"
import { LoginModal } from "@components/account/registration/LoginModal"
import { Headroom, ModalWithImage } from "@components/ui"
import {
  setChosenLocaleCookie,
  getChosenLocaleCookie,
  setActualLocaleCookie,
  getActualLocaleCookie,
} from "@lib/utils"
import dynamic from "next/dynamic"
import * as snippet from "@segment/snippet"

const DynamicFooter = dynamic(() => import("@components/common/Footer"))
const DynamicCart = dynamic(() => import("@components/common/Cart"))
const DynamicNavSideBar = dynamic(() => import("@components/common/NavSidebar"))

interface Props {
  preview: boolean
  hideSubscribeFooter: boolean
}

/**
 * To prevent segment from loading twice
 * we create alreadyRendered and set it to true the first time it loads
 * Then if it's already true (already rendered), we quit the function
 */
let alreadyRendered = false
function renderSegmentSnippet() {
  if (alreadyRendered) {
    return
  }
  alreadyRendered = true
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY || "0Ake7nHfJtvUtLu8b214dqDdrnLP3fDL",
    page: false,
  }

  if (process.env.NODE_ENV === "development") {
    return snippet.max(opts)
  }

  return snippet.min(opts)
}

function renderTikTokScript() {
  return `!function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        
          ttq.load('C6ADS1BIKDUOM9I7TB10');
          ttq.page();
        }(window, document, 'ttq');`
}

export const Layout: FC<Props> = ({ children, preview, hideSubscribeFooter }) => {
  const site = useSite()
  const [modalOpen, setModalOpen] = useState(false)
  const router = useRouter()
  const { setCurrency } = useCustomer()
  const { locale: next_locale } = useGeoIp()

  // This can be retrieved from contentful in the future
  const currencyDictionary: any = { "en-GB": "GBP", "en-US": "USD" }
  // All valid locales outside of the US with their own stores
  const separateLocales = Object.keys(currencyDictionary).filter(locale => locale !== "en-US")
  // resolvedNextLocale will resolve to either "en-GB" or "en-US", as people visiting from the UK should be "en-GB", and everyone else is "en-US"
  const resolvedNextLocale = separateLocales.includes(next_locale) ? next_locale : "en-US"

  useEffect(() => {
    const savedChosenLocale = getChosenLocaleCookie()
    const savedActualLocale = getActualLocaleCookie()

    // Visitor's resolved locale exists and has changed, check if they want to change stores
    if (
      savedActualLocale &&
      savedActualLocale !== resolvedNextLocale &&
      resolvedNextLocale !== router.locale
    ) {
      setActualLocaleCookie(resolvedNextLocale)
      setModalOpen(true)
      return
    }

    // No chosen locale saved in cookies, and their resolved locale is different to the store locale
    if (!savedChosenLocale && resolvedNextLocale !== router.locale) {
      setModalOpen(true)
      return
    }

    // There is a chosen locale saved, and it's different to the store locale
    if (savedChosenLocale && savedChosenLocale !== router.locale) {
      setModalOpen(true)
      return
    }
  }, [resolvedNextLocale])

  const { setNewCurrencyCheckout } = useCheckout()

  const handleBtnClick = async (locale: any) => {
    setChosenLocaleCookie(locale)
    await setCurrency(currencyDictionary[locale])
    await setNewCurrencyCheckout(locale?.split("-")?.[1], locale)
    router.push(router.asPath, "", { locale: locale })
    setModalOpen(false)
  }

  // // Here we are only loading these scripts after a delay AND a scroll is detected
  // useEffect(() => {
  //   const scroll = new Promise<void>((resolve, reject) => {
  //     window.addEventListener(
  //       'scroll',
  //       () => {
  //         resolve()
  //       },
  //       { once: true, passive: true }
  //     )
  //   })

  //   const wait = (milliseconds: number) =>
  //     new Promise<void>(resolve => {
  //       setTimeout(() => {
  //         resolve()
  //       }, milliseconds)
  //     })

  //   Promise.all([scroll, wait(4000)]).then(() => {
  //     const srcs = [
  //       'https://www.lightboxcdn.com/vendor/2c0639cc-7233-4329-b400-77fb46e8fa9d/lightbox_inline.js',
  //       'https://widget.wickedreports.com/HauteHijab/trackfu.js',
  //       'https://widget.wickedreports.com/v2/2928/wr-b4b4772359087e43bafbad8fd53a0326.js',
  //     ]
  //     srcs.forEach(src => {
  //       const script = document.createElement('script') as HTMLScriptElement
  //       script.src = src
  //       document.head.appendChild(script)
  //     })
  //   })
  // }, [])

  /*
   * !!!IMPORTANT!!!
   * Script elements with dangerouslySetInnerHTML will execute more than once
   * Check how we fixed it for segment in the renderSnippet function
   */

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{ __html: renderSegmentSnippet() }}
        strategy="afterInteractive"
      />
      <Script
        dangerouslySetInnerHTML={{ __html: renderTikTokScript() }}
        strategy="afterInteractive"
      />
      <Script
        src="//eu.cookie-script.com/s/f1945be72ff8703c4d1206422ce4877d.js"
        strategy="lazyOnload"
      />
      <Script
        src="//ca.cookie-script.com/s/f1945be72ff8703c4d1206422ce4877d.js"
        strategy="lazyOnload"
      />
      <Script src="https://assets.xgen.dev/js/jfjnpjza06deio1eud4794dbi8z1htp9/gtmIntegration.js" />
      <Script
        src="https://api.goaffpro.com/loader.js?shop=hautehijab.myshopify.com"
        strategy="lazyOnload"
      />
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: ` var _dcq = _dcq || [];
          var _dcs = _dcs || {};          
          _dcs.account = '6106886';
        `,
        }}
      />
      <Script src="https://tag.getdrip.com/6106886.js" strategy="lazyOnload" />
      <Script src="https://www.upsellit.com/active/hautehijab.jsp" strategy="lazyOnload" />
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=GTM-KLQZVV9"
        strategy="lazyOnload"
      />
      <Script
        src="https://www.lightboxcdn.com/vendor/2c0639cc-7233-4329-b400-77fb46e8fa9d/lightbox_inline.js"
        strategy="lazyOnload"
      />
      <Script src="https://widget.wickedreports.com/HauteHijab/trackfu.js" strategy="lazyOnload" />
      <Script
        src="https://widget.wickedreports.com/v2/2928/wr-b4b4772359087e43bafbad8fd53a0326.js"
        strategy="lazyOnload"
      />
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `!function(e,t,i){e.thoughtmetricQueue=[],e.thoughtmetric=function(){e.thoughtmetricQueue.push(arguments)};var c=t.createElement("script");c.async=1,c.src="https://pixel.thoughtmetric.io/tmpixel.min.js?t="+864e5*Math.ceil(new Date/864e5);var n=t.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}(window,document); thoughtmetric("init","ID-4a86b76bf3652f72"); thoughtmetric("pageview");`,
        }}
      />
      <Headroom>
        {preview && (
          <Preview>
            This page is a preview.{" "}
            <u>
              <a href="/api/exit-preview">Click here</a>
            </u>{" "}
            to exit preview mode.
          </Preview>
        )}
        <AnnouncementBar />
        <Navbar />
      </Headroom>
      <DynamicNavSideBar />
      <DynamicCart />
      <main>
        {children}
        {!hideSubscribeFooter && <Subscribe />}
        <LoginModal />
        <SearchModal />
        {site?.chooseGeoStoreModal && (
          <ModalWithImage
            modalOpen={modalOpen}
            closeModal={() => handleBtnClick(router.locale)}
            richText={site.chooseGeoStoreModal.richText}
            buttons={site.chooseGeoStoreModal.buttonsCollection.items}
            image={site.chooseGeoStoreModal.image}
            imageMobile={site.chooseGeoStoreModal.imageMobile}
            handleLeftBtnClick={() => handleBtnClick("en-US")}
            handleRightBtnClick={() => handleBtnClick("en-GB")}
          />
        )}
      </main>
      <DynamicFooter />
    </>
  )
}

export default Layout

const Preview = styled.div`
  background: white;
  padding: 1rem;
`
